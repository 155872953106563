import React from 'react';
import Layout from '../components/Layout';
import EngagementsTemplate from '../templates/EngagementsTemplate';
import { graphql } from 'gatsby';

const EngagementsPage = ({ data }) => {
	const engagements = data.all.edges.map((edge) => ({
		name: edge.node.name,
		trustees: edge.node.trustees,
		industry: edge.node.industry,
		slug: edge.node.fields.slug,
		is_active: edge.node.is_active,
	}));
	return (
		<Layout>
			<EngagementsTemplate engagements={engagements} />
		</Layout>
	);
};

export default EngagementsPage;

export const query = graphql`
	{
		all: allEngagementsJson(
			filter: { is_published: { eq: true } }
			sort: { order: ASC, fields: [name] }
		) {
			edges {
				node {
					name
					trustees {
						trustee
					}
					is_active
					industry
					fields {
						slug
					}
				}
			}
		}
	}
`;
