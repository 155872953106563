import React from 'react';
import styled from 'styled-components';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

export default ({
	engagements,
	industryFilter,
	statusFilter,
	searchFilter,
}) => {
	return (
		<Listings>
			<div className="container" style={{ padding: '0 2rem' }}>
				<div className="columns">
					{prepare(engagements, industryFilter, statusFilter, searchFilter).map(
						(colItems, index) => (
							<div className="column is-half" key={index}>
								{colItems.map((item, index) => (
									<div key={index}>
										<h2>{item.group}</h2>
										{item.children.map((e, index) => (
											<Engagement key={`${e.slug}`}>
												<h3>
													<a href={e.slug}>{e.name}</a>
												</h3>
											</Engagement>
										))}
									</div>
								))}
							</div>
						)
					)}
				</div>
			</div>
		</Listings>
	);
};

const Listings = styled.div`
	padding: 4rem 0;
`;

const Engagement = styled.div`
	padding: 1rem 0;
	h2 {
		font-weight: 700;
		font-size: 2rem;
		margin: 0 0 1.5rem 0;
	}
	h3 {
		margin: 0.25rem 0;
		line-height: 1;
		font-size: 1.25rem;
		font-weight: 300;
	}
	h4 {
		margin: 0;
		font-weight: 400;
		line-height: 1.2;
	}
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
`;

// Sort, group and weight engagement groups.
const sortGroupWeight = (engagements) => {
	// Sort by industry name alphabetically.
	engagements.sort((a, b) => {
		if (a.industry < b.industry) {
			return -1;
		} else if (a.industry > b.industry) {
			return 1;
		} else {
			return 0;
		}
	});

	// Group by first char of industry name.
	let data = engagements.reduce((r, e) => {
		let group = e.industry;
		if (!r[group]) r[group] = { group, children: [e] };
		else r[group].children.push(e);
		return r;
	}, {});

	let result = moveOtherToEnd(Object.values(data));

	// Map to object with weighted values.
	return result.map((item) => ({
		weight: item.children.length + 1,
		item: item,
	}));
};

const moveOtherToEnd = (arr) => {
	return arr
		.filter((item) => item.group !== 'Other')
		.concat(arr.filter((item) => item.group === 'Other'));
};

// Use weighted values to split content into columns.
const chunk = (arr, n) => {
	const list = arr;

	const totalWeight = list.reduce((acc, cur) => acc + cur.weight, 0);
	const half = totalWeight / 2;

	let firstIndexPastHalf;
	let sum = 0;

	for (let i = 0; i < list.length; i++) {
		sum += list[i].weight;
		if (!firstIndexPastHalf && sum >= half) {
			firstIndexPastHalf = i + 1;
		}
	}

	const firstHalf = list.slice(0, firstIndexPastHalf).map((item) => item.item);
	const secondHalf = list
		.slice(firstIndexPastHalf, list.length)
		.map((item) => item.item);

	return [firstHalf, secondHalf];
};

const prepare = (engagements, industryFilter, statusFilter, searchFilter) => {
	let filtered = [];

	// Status.
	if (statusFilter === 'active') {
		filtered = engagements.filter((eng) => eng.is_active);
	} else if (statusFilter === 'closed') {
		filtered = engagements.filter((eng) => !eng.is_active);
	} else {
		filtered = engagements;
	}

	// Industry.
	if (industryFilter) {
		filtered = filtered.filter((eng) => eng.industry === industryFilter);
	}

	// Search.
	if (searchFilter) {
		filtered = filtered.filter((eng) => {
			return eng.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;
		});
	}

	const items = sortGroupWeight(filtered);

	return chunk(items, 2);
};
