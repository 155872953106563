import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import EngagementListings from '../components/EngagementListings';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

import Select from 'react-select';

import common, { PageWrap } from '../styles/common';

export default ({ engagements }) => {
	const data = useStaticQuery(graphql`
		{
			industries: allIndustriesJson(sort: { fields: [name] }) {
				edges {
					node {
						name
					}
				}
			}
		}
	`);

	const industriesList = data.industries.edges.map(({ node }) => ({
		value: node.name,
		label: node.name,
	}));

	const [statusFilter, setStatusFilter] = useState('all');
	const [industryFilter, setIndustryFilter] = useState();
	const [searchValue, setSearchValue] = useState('');

	return (
		<PageWrap>
			<Helmet>
				<title>Engagements | Spergel Corporate</title>
				<style>{`div#chat-widget-container {display: none;}`}</style>
			</Helmet>
			<Masthead
				image="/img/SpergelCorporate_Engagement_Masthead.jpg"
				title="Engagements"
			/>
			<Wrap>
				<div className="container" style={{ padding: '0 2rem' }}>
					<div className="columns">
						<div className="column">
							<Select
								isClearable
								options={industriesList}
								styles={customStyles}
								isSearchable={false}
								placeholder={'Industry'}
								onChange={(e) => {
									setIndustryFilter(e ? e.value : null);
								}}
							/>
						</div>
						<div className="column">
							<Select
								isClearable
								options={[
									{ label: 'Active', value: 'active' },
									{ label: 'Closed', value: 'closed' },
								]}
								styles={customStyles}
								isSearchable={false}
								placeholder={'Status'}
								onChange={(e) => {
									setStatusFilter(e ? e.value : null);
								}}
							/>
						</div>
						<div className="column">
							<input
								type="text"
								className="search"
								value={searchValue}
								onChange={(e) => {
									setSearchValue(e.target.value);
								}}
								placeholder="Search"
							/>
						</div>
					</div>
				</div>
			</Wrap>
			<EngagementListings
				engagements={engagements}
				industryFilter={industryFilter}
				statusFilter={statusFilter}
				searchFilter={searchValue}
			/>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

// react-select style API.
const customStyles = {
	control: (provided) => ({
		...provided,
		width: '100%',
		fontSize: '1.5rem',
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'none',
		borderBottom: `2px solid ${common.lightGrey}`,
		paddingBottom: '0.5rem',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		opacity: 1,
		color: 'black',
	}),
};

const Wrap = styled.div`
	padding: 4rem 0;

	text-align: center;
	h2 {
		text-align: center;
		margin-bottom: 4rem;
	}
	a {
		text-decoration: none;
		color: ${common.spergelRed};
		font-size: 1rem;
		font-weight: 700;
	}
	.filter-label {
		margin: 0px;
		font-size: 1.5rem;
		font-weight: 300;
		text-align: left;
		@media only screen and (min-width: 768px) {
			text-align: right;
		}
	}
	.search {
		width: 100%;
		border-radius: 0;
		border: none;
		border-bottom: 2px solid ${common.lightGrey};
		font-size: 1.5rem;
		padding: 7px 0.5rem 0.5rem 0.5rem;
		font-family: ${common.baseFont};
		&::placeholder {
			font-family: ${common.baseFont};
		}
	}
`;
